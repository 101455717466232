import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Inline from "../../../../../components/inline"
import Score from "../../../../../components/score"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"
import SolutionButton from "../../../../../components/solution-button"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"

const Page = () => {
  const { answerStatusForArrayValue, getStatsForTaskWithArray } =
    useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })
  const task = {
    courseId: "jahreszeiten",
    chapterId: "01-jahreszeitengedicht",
    taskId: "lesen-und-einpraegen",
  }
  const statistics = getStatsForTaskWithArray(task)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht/worte-und-wortarten"
        />
      }
    >
      <Seo title="Lesen & Einprägen" />
      <Stack>
        <FeedbackHeaderProgress
          variant="solution"
          correct={statistics.correct}
          total={statistics.correct + statistics.missed}
        />
        <Inline alignY="center">
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "gesang" })}
          >
            Gesang
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "natur" })}
          >
            Natur
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "tiefe" })}
          >
            Tiefe
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "sonne" })}
          >
            Sonne
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "blumen" })}
          >
            Blumen
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "wolken" })}
          >
            Wolken
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({
              ...task,
              value: "harmonie",
            })}
          >
            Harmonie
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "freude" })}
          >
            Freude
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({
              ...task,
              value: "schatten",
            })}
          >
            Schatten
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({
              ...task,
              value: "daemmer",
            })}
          >
            Dämmer
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "reben" })}
          >
            Reben
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "mensch" })}
          >
            Mensch
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "himmel" })}
          >
            Himmel
          </SolutionButton>
        </Inline>
        <Score statistics={statistics} />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
